
import { Component, Mixins } from 'vue-property-decorator';
import { EventBus } from '../../../utils/EventBus';

import Icon from './../Icons/Icon.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({
    components: {
        Icon
    }
})
export default class Bet extends Mixins(GlobalMixin) {
    betOptionsIsActive: boolean = false;
    strokeImageSource = `./assets/${window.lang}/icons/stroke.png`;
    betSource = `./assets/${window.lang}/ui/texts/gameControl_bet.png`;

    mounted () {
        document.addEventListener('click', this.handleDocumentClick.bind(this));
    }

    handleDocumentClick () {
        this.betOptionsIsActive = false;
    }

    handleBetPanelClick (): void {
        this.playSound('UI_Click_1');
        if (!this.controlsIsDisabled) {
            this.betOptionsIsActive = !this.betOptionsIsActive;
        }
    }

    handleBetOptionClick (clickedBetOption: number): void {
        if (!this.controlsIsDisabled) {
            this.playSound('UI_Click_2');
            this.$store.dispatch('stake/setValue', clickedBetOption);
            EventBus.$emit('STAKE_CHANGE', { type: 'STAKE_CHANGE', value: this.stakeValue });
            this.betOptionsIsActive = false;
        }
    }

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }

    get controlsIsDisabled (): boolean {
        return this.$store.state.controlsIsDisabled;
    }

    get betOptions (): number[] {
        return this.$store.state.stake.steps;
    }

    get currency (): string {
        return this.$store.state.locale.currency;
    }
}
