
import { Component, Mixins } from 'vue-property-decorator';
import PrimaryButton from './../shared/Buttons/PrimaryButton.vue';

import { EventBus } from '../../utils/EventBus';
import GlobalMixin from '../../mixins/GlobalMixin.vue';

@Component({
    components: {
        PrimaryButton
    }
})
export default class Autoplay extends Mixins(GlobalMixin) {
    playButtonHover: boolean = false;
    buttonPlayHoverSource = `./assets/${window.lang}/fs_popup/texts/fs_popup_play_normal.png`;
    buttonPlayNormalSource = `./assets/${window.lang}/fs_popup/texts/fs_popup_play_inactive.png`;

    buttonPlayNormal = `./assets/${window.lang}/fs_popup/fs_pop_button_normal.png`;
    buttonPlayHover = `./assets/${window.lang}/fs_popup/fs_pop_button_hover.png`;

    handlePlayButtonClick (): void {
        if (this.isFreespinsPlayButtonActive) {
            this.playSound('UI_Click_2');
            EventBus.$emit('FS_PLAY_BUTTON_CLICKED', { type: 'FS_PLAY_BUTTON_CLICKED' });
            this.$store.dispatch('toggleFSPlayButton', false);
            this.playButtonHover = false;
        }
    }

    get isFreespinsPopupActive (): boolean {
        return this.$store.state.popup.isFreespinsPopupActive;
    }

    get isFreespinsPlayButtonActive (): boolean {
        return this.$store.state.isFreespinsPlayButtonActive;
    }
}
