
import { Component, Watch, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';

import Dropdown from './../../shared/Dropdown/Dropdown.vue';
import SwitchCheckbox from './../../shared/SwitchCheckbox/SwitchCheckbox.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({
    components: {
        Dropdown,
        SwitchCheckbox
    }
})
export default class Autoplay extends Mixins(GlobalMixin) {
    selectedRound: any = this.totalSpins[0];
    selectedLostLimit: any = this.lossLimits[0];
    selectedSingleWinLimit: any = this.singleWinLimits[0];
    autoPlayHeader: string = `./assets/${window.lang}/settings/texts/settings_autoplay_head.png`;
    autoPlayTotalSpins: string = `./assets/${window.lang}/settings/texts/settings_autoplay_totalspins.png`;
    autoPlayLossLimit: string = `./assets/${window.lang}/settings/texts/settings_autoplay_lossLimit.png`;
    autoPlaySingleWinLossLimit: string = `./assets/${window.lang}/settings/texts/settings_autoplay_singleWinLimit.png`;
    autoPlayStart: string = `./assets/${window.lang}/settings/texts/settings_autoplay_start.png`;
    balanceLessThanBet: boolean = this.balance < this.stakeValue;
    created () {
        if (this.lossLimitsLowerThanBalance.length) {
            this.$store.dispatch('autoplay/setFields', { totalSpins: this.selectedRound.value, limitLoss: this.selectedLostLimit.value, singleWinLimit: this.selectedSingleWinLimit.value });
        } else {
            this.$store.dispatch('autoplay/setFields', { totalSpins: this.selectedRound.value, limitLoss: null, singleWinLimit: this.selectedSingleWinLimit.value });
        }
    }

    @Watch('selectedRound')
    handleSelectedRoundChange (selectedRound: any) {
        this.$store.dispatch('autoplay/setFields', { totalSpins: selectedRound.value });
    }

    @Watch('selectedLostLimit')
    handleLossLimitChange (lossLimit: any) {
        this.$store.dispatch('autoplay/setFields', { limitLoss: lossLimit.value });
    }

    @Watch('selectedSingleWinLimit')
    handleSingleWinLimitChange (singleWinLimit: any) {
        this.$store.dispatch('autoplay/setFields', { singleWinLimit: singleWinLimit.value });
    }

    handleAutoplayStartClick (): void {
        if (this.balanceLessThanBet) { return; }
        EventBus.$emit(EVENTS.AUTO_PLAY_ACTIVATED, { type: EVENTS.AUTO_PLAY_ACTIVATED, value: { ...this.$store.state.autoplay.fields, initBalance: this.balance } });
        this.$store.dispatch('popup/hideAutoplayModal');
        this.playSound('UI_Click_2');
    }

    get autoplayRounds (): number[] {
        return this.$store.state.autoplay.autoplayRounds;
    }

    get lang (): string {
        return window.lang;
    }

    get lossLimitsLowerThanBalance () {
        if (this!.$options!.filters!.lessThanBalance) {
            return this!.$options!.filters!.lessThanBalance(this.lossLimits);
        }
        return this.lossLimits;
    }

    get lossLimits (): any {
        return this.lossLimit.slice().map(lossLimit => {
            // Тук разделяме stake на 100. Това не трябва да е тука и не трябва да работи по този начин. Трябва да го изнесем в отделен клас или функция.
            if (this.stakeValue <= this.balance / 10) {
                lossLimit.value = lossLimit.initValue * (this.stakeValue / 100);
                // lossLimit.textContent = '€' + lossLimit.value.toFixed(2);
                lossLimit.textContent = this!.$options!.filters!.currencyFormat(lossLimit.value);
                return lossLimit;
            } else {
                lossLimit.value = (lossLimit.initValue / 10) * (this.stakeValue / 100);
                // lossLimit.textContent = '€' + lossLimit.value.toFixed(2);
                lossLimit.textContent = this!.$options!.filters!.currencyFormat(lossLimit.value);
                return lossLimit;
            }
        });
    }

    get singleWinLimits (): any {
        return this.singleWinLimit.slice().map(singleWinLimit => {
            if (singleWinLimit.key === 0) {
                return singleWinLimit;
            }

            singleWinLimit.value = singleWinLimit.initValue * (this.stakeValue / 100);
            singleWinLimit.textContent = this!.$options!.filters!.currencyFormat(singleWinLimit.value);
            return singleWinLimit;
        });
    }

    get totalSpins (): any[] {
        return this.$store.state.autoplay.totalSpins;
    }

    get lossLimit (): any[] {
        return this.$store.state.autoplay.lossLimit;
    }

    get singleWinLimit (): any[] {
        return this.$store.state.autoplay.singleWinLimit;
    }

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }
    get balance () {
        return this.$store.state.balance.value;
    }
}
