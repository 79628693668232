
import Popup from './Popup.vue';

import Autoplay from './../../popupContents/autoplay/Autoplay.vue';
import PromotionalFreeSpins from './../../promotionalFreeSpins/PromotionalFreeSpins.vue';
import GameUnfinished from './../../popupContents/gameUnfinished/GameUnfinished.vue';
import Informational from './../../popupContents/informational/Informational.vue';
import Error from './../../popupContents/error/Error.vue';
import RealityCheck from './../../popupContents/realitycheck/RealityCheck.vue';
import History from './../../popupContents/history/History.vue';

import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        Popup,
        PromotionalFreeSpins,
        GameUnfinished,
        Informational,
        Error,
        RealityCheck,
        Autoplay,
        History
    }
})
export default class Popups extends Vue {
    get isPromotionalFreeSpinsPopupActive (): boolean {
        return this.$store.state.popup.isPromotionalFreeSpinsPopupActive;
    }

    get isGameUnfinishedPopupActive (): boolean {
        return this.$store.state.popup.isGameUnfinishedPopupActive;
    }

    get isInformationalPopupActive (): boolean {
        return this.$store.state.popup.isInformationalPopupActive;
    }
    get isErrorPopupActive (): boolean {
        return this.$store.state.popup.isErrorPopupActive;
    }
    get isRealityCheckPopupActive (): boolean {
        return this.$store.state.popup.isRealityCheckPopupActive;
    }
    get informationText (): boolean {
        return this.$store.state.popup.informationText;
    }
    get errorText (): boolean {
        return this.$store.state.popup.errorText;
    }
    get realityCheckData (): boolean {
        return this.$store.state.popup.realityCheckData;
    }
    get isAutoplayModalActive (): boolean {
        return this.$store.state.popup.isAutoplayModalActive;
    }
    get isHistoryPopupActive (): boolean {
        return this.$store.state.popup.isHistoryPopupActive;
    }
}
