
import { Component, Vue } from 'vue-property-decorator';
import HeaderControls from './../shared/HeaderControls/HeaderControls.vue';
import Balance from './../shared/Balance/Balance.vue';

@Component({
    components: {
        HeaderControls,
        Balance
    }
})
export default class GameUIContainer extends Vue {
    upperPanelSource = `./assets/${window.lang}/common/ui_bar.png`;

    get canAppendGameUI () {
        return this.$store.state.canAppendGameUI;
    }
}
