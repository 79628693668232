
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class PaytableSymbol extends Vue {
    @Prop({ default: '' })
    image?: string;

    @Prop({ default: '' })
    background?: string;

    @Prop({ default: '' })
    symbolWin?: string;

    @Prop({ default: '' })
    symbol?: string;

    get symbolPays () {
        return this.$store.getters['paytable/getSymbolPays'](this.symbol);
    }

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }
}
