
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import anime from 'animejs';

@Component({})
export default class Balance extends Vue {
    balanceValue: number = 0;
    balanceSource = `./assets/${window.lang}/ui/texts/gameControl_balance.png`;

    @Watch('balance')
    onBalanceChanged (value: number, oldValue: number) {
        const duration = (value === oldValue || oldValue === 0) ? 0 : 1000;
        this.updateBalanceValue(value, oldValue, duration);
    }

    created () {
        this.updateBalanceValue(this.balance, 0, 0);
    }

    updateBalanceValue (value: number, oldValue: number, duration: number) {
        const balanceTarget = {
            value: oldValue
        };

        anime({
            targets: balanceTarget,
            value,
            duration,
            easing: 'linear',
            update: () => {
                this.balanceValue = Math.round(balanceTarget.value);
            },
            complete: () => {
                this.balanceValue = balanceTarget.value;
            }
        });
    }

    get balance () {
        return this.$store.state.balance.value;
    }
}
