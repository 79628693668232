
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GameBackground extends Vue {
    @Prop({ default: 'Example' })
    src!: string;

    @Prop({ default: 'Example' })
    alt!: string;
}
