
import { Component, Vue } from 'vue-property-decorator';
import GameBackground from './GameBackground.vue';
import GameContainer from './GameContainer.vue';
import DeviceOrientationMessage from './../shared/DeviceOrientationMessage/DeviceOrientationMessage.vue';

@Component({
    components: {
        GameBackground,
        GameContainer,
        DeviceOrientationMessage
    }
})
export default class GameUI extends Vue {
    get canShowDeviceOrientationMessage () {
        return this.$store.state.canShowDeviceOrientationMessage;
    }
    sourceBackground: string = `./assets/${window.lang}/common/desktop_bgr.jpg`;
}
