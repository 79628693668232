import { SpinButtonState } from './contracts/spinButton';

const state: SpinButtonState = {
    spinButtonIsActive: true,
    canShowStopButton: false,
    canSetOpacity: false
};

const actions = {
    makeSpinButtonDisable ({ commit }: any): void {
        commit('setSpinButtonDisable');
    },
    makeSpinButtonActive ({ commit }: any): void {
        commit('setSpinButtonActive');
    },
    showStopButton ({ commit }: any): void {
        commit('showStopButton');
    },
    hideStopButton ({ commit }: any): void {
        commit('hideStopButton');
    },
    setOpacity ({ commit }: any, value: boolean): void {
        commit('setOpacity', value);
    }
};

const mutations = {
    setOpacity (state: SpinButtonState, value: boolean) {
        state.canSetOpacity = value;
    },
    setSpinButtonDisable (state: SpinButtonState) {
        state.spinButtonIsActive = false;
    },
    setSpinButtonActive (state: SpinButtonState) {
        state.spinButtonIsActive = true;
    },
    showStopButton () {
        state.canShowStopButton = true;
    },
    hideStopButton () {
        state.canShowStopButton = false;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
