import { IStake } from './contracts/stake';

const state: IStake = { // TODO we need to set the initial value of the stake
    value: 10,
    steps: [10, 20, 30, 50, 100, 250, 500, 1000, 2500, 5000, 10000],
    stakePosition: 0
};

const actions = {
    increment ({ commit }: any): void {
        commit('increment');
    },
    decrement ({ commit }: any): void {
        commit('decrement');
    },
    setValue ({ commit }: any, value: number): void {
        commit('setValue', value);
    },
    setLimits ({ commit }: any, limits: any): void {
        const { legalBets, defaultBet }: { legalBets: number, defaultBet: number } = limits;

        commit('setSteps', legalBets);
        commit('setValue', defaultBet);
    }
};

const mutations = {
    increment (state: IStake): void {
        if (state.stakePosition < state.steps.length - 1) {
            state.stakePosition++;
        }

        state.value = state.steps[state.stakePosition];
    },
    decrement (state: IStake): void {
        if (state.stakePosition > 0) {
            state.stakePosition--;
        }

        state.value = state.steps[state.stakePosition];
    },
    setValue (state: IStake, value: number): void {
        const indexOfValue: number = state.steps.indexOf(value);
        if (indexOfValue >= 0) {
            state.stakePosition = indexOfValue;
            state.value = state.steps[state.stakePosition];
        }
    },
    setSteps (state: IStake, steps: any) {
        state.steps = steps;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
