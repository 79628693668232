import Vue from 'vue';
import Vuex from 'vuex';
import balance from './shared/balance';
import history from './shared/history';
import closeButton from './shared/LoadingScreen/closeButton';
import loadingScreen from './shared/LoadingScreen/loadingScreen';
import locale from './shared/locale';
import paytable from './shared/paytable';
import popup from './shared/popup';
import rules from './shared/rules';
import stake from './shared/stake';
import autoplay from './slot/autoplay';
import promotionalFreeSpins from './slot/promotionalFreeSpins';
import spinButton from './slot/spinButton';
import turboSpinButton from './slot/turboSpinButton';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        canShowDeviceOrientationMessage: false,
        canAppendGameUI: false,
        LoadingScreenIsShown: true,
        controlsIsDisabled: false,
        isFreespinsPlayButtonActive: false,
        canShowCurrencyCode: false,
        headerControlsIsActive: true,
        IOSgifAnimationIsActive: false,
        hardError: false,
        hardErrorMessage: '',
        realmoney: false,
        currencyShow: 'FUN'
    },
    actions: {
        currencyShow ({ commit }, currency: string) {
            commit('currencyShow', currency);
        },
        setRealMoney ({ commit }, moneymode: string) {
            commit('setRealMoney', moneymode);
        },
        setValueToIOSGifAnimation ({ commit }, value: boolean) {
            commit('setValueToIOSGifAnimation', value);
        },
        appendGameUI ({ commit }) {
            commit('appendGameUI');
        },
        enableDeviceOrientationMessage ({ commit }) {
            commit('enableDeviceOrientationMessage');
        },
        disableDeviceOrientationMessage ({ commit }) {
            commit('disableDeviceOrientationMessage');
        },
        disableControls ({ commit }) {
            commit('disableControls');
        },
        enableControls ({ commit }) {
            commit('enableControls');
        },
        toggleFSPlayButton ({ commit }, value) {
            commit('toggleFSPlayButton', value);
        },
        setCurrenyCodeValue ({ commit }, value) {
            commit('setCurrenyCodeValue', value);
        },
        setHeaderControlsIsActive ({ commit }, value) {
            commit('setHeaderControlsIsActive', value);
        },
        showHardError ({ commit }, data) {
            commit('showHardError', data);
        }
    },
    mutations: {
        currencyShow (state, currencyShow) {
            state.currencyShow = currencyShow;
        },
        setRealMoney (state, moneymode) {
            state.realmoney = moneymode;
        },
        setValueToIOSGifAnimation (state, value) {
            state.IOSgifAnimationIsActive = value;
        },
        setHeaderControlsIsActive (state, value) {
            state.headerControlsIsActive = value;
        },
        appendGameUI (state) {
            state.canAppendGameUI = true;
        },
        setCurrenyCodeValue (state, value) {
            state.canShowCurrencyCode = value;
        },
        enableDeviceOrientationMessage (state) {
            state.canShowDeviceOrientationMessage = true;
        },
        disableDeviceOrientationMessage (state) {
            state.canShowDeviceOrientationMessage = false;
        },
        disableControls (state) {
            state.controlsIsDisabled = true;
        },
        enableControls (state) {
            state.controlsIsDisabled = false;
        },
        toggleFSPlayButton (state, value) {
            state.isFreespinsPlayButtonActive = value;
        },
        showHardError (state, data) {
            state.hardError = true;
            state.hardErrorMessage = data;
        }
    },
    modules: {
        autoplay,
        spinButton,
        stake,
        loadingScreen,
        closeButton,
        turboSpinButton,
        balance,
        popup,
        promotionalFreeSpins,
        paytable,
        history,
        locale,
        rules
    }
});
