import { IBalance } from './contracts/balance';

const state: IBalance = {
    value: 0
};

const actions = {
    setValue ({ commit }: any, value: number) {
        commit('setValue', value);
    }
};

const mutations = {
    setValue (state: IBalance, value: number) {
        state.value = value;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
