
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class IOSFullscreenGifAnimation extends Vue {
    timeout: any = null;
    gifAnimationPath: string = `./assets/${window.lang}/common/gif_02.gif`;

    mounted () {
        this.timeout = setTimeout(() => {
            this.$emit('canClose');
        }, 11000);
    }

    private handleClick () {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.$emit('canClose');
    }
}
