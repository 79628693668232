export default {
    cs_cz: {
        'game:portrait.mode.warning':
            'Chcete-li pokračovat ve hře, přepněte zpět do režimu na výšku'
    },
    da_dk: {
        'game:portrait.mode.warning':
            'Skift venligst tilbage til portrættilstand for at fortsætte spillet'
    },
    de_de: {
        'game:portrait.mode.warning':
            'Bitte wechseln Sie zurück in den Hochformatmodus, um das Spiel fortzusetzen'
    },
    el_gr: {
        'game:portrait.mode.warning':
            'Επιστρέψτε στη λειτουργία πορτραίτου για να συνεχίσετε το παιχνίδι'
    },
    en_gb: {
        'game:portrait.mode.warning':
            'Please switch back to portrait mode to continue the game'
    },
    en_sc: {
        'game:portrait.mode.warning':
            'Please switch back to portrait mode to continue the game'
    },
    en_us: {
        'game:portrait.mode.warning':
            'Please switch back to portrait mode to continue the game'
    },
    es_es: {
        'game:portrait.mode.warning':
            'Vuelva a cambiar al modo vertical para continuar el juego.'
    },
    es_mx: {
        'game:portrait.mode.warning':
            'Vuelva a cambiar al modo vertical para continuar el juego.'
    },
    et_ee: {
        'game:portrait.mode.warning':
            'Mängu jätkamiseks lülitage tagasi portreerežiimile'
    },
    fi_fi: {
        'game:portrait.mode.warning':
            'Vaihda takaisin muotokuvatilaan jatkaaksesi peliä'
    },
    fr_be: {
        'game:portrait.mode.warning':
            'Veuillez revenir en mode portrait pour continuer le jeu'
    },
    fr_fr: {
        'game:portrait.mode.warning':
            'Veuillez revenir en mode portrait pour continuer le jeu'
    },
    hr_hr: {
        'game:portrait.mode.warning':
            'Za nastavak igre vratite se na portretni način'
    },
    hu_hu: {
        'game:portrait.mode.warning':
            'A játék folytatásához váltson vissza álló módba'
    },
    id_id: {
        'game:portrait.mode.warning':
            'Silakan beralih kembali ke mode potret untuk melanjutkan permainan'
    },
    it_it: {
        'game:portrait.mode.warning':
            'Torna alla modalità verticale per continuare il gioco'
    },
    ja_jp: {
        'game:portrait.mode.warning':
            'ゲームを続行するには、ポートレート モードに戻してください'
    },
    ka_ge: {
        'game:portrait.mode.warning':
            'გთხოვთ, გადახვიდეთ პორტრეტის რეჟიმში თამაშის გასაგრძელებლად'
    },
    km_kh: {
        'game:portrait.mode.warning':
            'សូមប្តូរត្រឡប់ទៅរបៀបបញ្ឈរវិញ ដើម្បីបន្តហ្គេម'
    },
    ko_kr: {
        'game:portrait.mode.warning':
            '게임을 계속하려면 세로 모드로 다시 전환하세요.'
    },
    lt_lt: {
        'game:portrait.mode.warning':
            'Norėdami tęsti žaidimą, grįžkite į portreto režimą'
    },
    ms_my: {
        'game:portrait.mode.warning':
            'Sila tukar kembali ke mod potret untuk meneruskan permainan'
    },
    nb_no: {
        'game:portrait.mode.warning':
            'Vennligst bytt tilbake til portrettmodus for å fortsette spillet'
    },
    nl_be: {
        'game:portrait.mode.warning':
            'Schakel terug naar de portretmodus om door te gaan met het spel'
    },
    nl_nl: {
        'game:portrait.mode.warning':
            'Schakel terug naar de portretmodus om door te gaan met het spel'
    },
    pl_pl: {
        'game:portrait.mode.warning':
            'Aby kontynuować grę, przełącz się z powrotem do trybu pionowego'
    },
    pt_br: {
        'game:portrait.mode.warning':
            'Volte para o modo retrato para continuar o jogo'
    },
    pt_pt: {
        'game:portrait.mode.warning':
            'Volte para o modo retrato para continuar o jogo'
    },
    ro_ro: {
        'game:portrait.mode.warning':
            'Vă rugăm să reveniți la modul portret pentru a continua jocul'
    },
    ru_ru: {
        'game:portrait.mode.warning':
            'Пожалуйста, вернитесь в портретный режим, чтобы продолжить игру.'
    },
    sk_sk: {
        'game:portrait.mode.warning':
            'Ak chcete pokračovať v hre, prepnite späť do režimu na výšku'
    },
    sv_se: {
        'game:portrait.mode.warning':
            'Växla tillbaka till porträttläge för att fortsätta spelet'
    },
    th_th: {
        'game:portrait.mode.warning':
            'โปรดเปลี่ยนกลับเป็นโหมดแนวตั้งเพื่อเล่นเกมต่อ'
    },
    tr_tr: {
        'game:portrait.mode.warning':
            'Oyuna devam etmek için lütfen portre moduna geri dönün'
    },
    vi_vn: {
        'game:portrait.mode.warning':
            'Vui lòng chuyển về chế độ dọc để tiếp tục trò chơi'
    },
    zh_cn: {
        'game:portrait.mode.warning': '请切换回竖屏模式继续游戏'
    },
    zh_tw: {
        'game:portrait.mode.warning': '請切換回豎屏模式繼續遊戲'
    }
}
