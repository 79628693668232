
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import LoadingScreen from './../loadingScreen/LoadingScreen.vue';
import DefaultLayout from './../../layouts/Default.vue';

import Popups from './../shared/Popup/Popups.vue';
import Paytable from './../paytable/Paytable.vue';
import FSPopup from './../FSPopup/FSPopup.vue';
import Rules from './../help/Help.vue';
import CurrencyCode from './../currencyCode/CurrencyCode.vue';
import Clock from './../clock/Clock.vue';
import PromotionalFreeSpinsCounter from './../PromotionalFreeSpinsCounter/PromotionalFreeSpinsCounter.vue';
import IOSFullscreenGifAnimation from './../IOSFullscreenGifAnimation/IOSFullscreenGifAnimation.vue';

@Component({
    components: {
        DefaultLayout,
        LoadingScreen,
        Popups,
        Paytable,
        FSPopup,
        Rules,
        CurrencyCode,
        Clock,
        PromotionalFreeSpinsCounter,
        IOSFullscreenGifAnimation
    }
})
export default class GameUIContainer extends Vue {
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    width: number = 750;
    height: number = 1333;
    popupInformation: string ='';

    canvasElement: (HTMLCanvasElement | null) = null;

    mounted () {
        this.$nextTick(() => {
            EventBus.$on('GAME_CANVAS_READY', this.onCanvasReady);
            EventBus.$on('RESIZE_WINDOW', this.handleResize);
            EventBus.$on('INFORMATION_RECEIVE', this.handleInformation);
            this.scaleUIContainer(window.__SILVERBACK__.ScaleOptions);
        });
    }

    private handleCanCloseIOSGifAnimation () {
        this.$store.dispatch('setValueToIOSGifAnimation', false);
    }

    onCanvasReady () {
        const initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
            initialLoader.style.display = 'none';
        }

        this.canvasElement = document.querySelector('#game > canvas') as HTMLCanvasElement;
        this.scaleUIContainer(window.__SILVERBACK__.ScaleOptions);
    }

    handleInformation (information: string) {
        this.popupInformation = information;
    }

    handleResize (context: any) {
        this.scaleUIContainer(context.value);
    }

    scaleUIContainer (scaleOptions?: any) {
        if (!this.canvasElement || !scaleOptions) {
            return;
        }

        const canvasWidth = this.canvasElement.width;
        const canvasHeight = this.canvasElement.height;

        if (scaleOptions.height * scaleOptions.resizeCoefficent < window.innerHeight) {
            (this.$refs.gameUIContainer as HTMLElement).style.height = this.height + 'px';
        } else {
            (this.$refs.gameUIContainer as HTMLElement).style.height = this.height + 'px';
        }
        (this.$refs.gameUIContainer as HTMLElement).style.transform = `matrix(${scaleOptions.scale}, 0, 0, ${scaleOptions.scale}, 0, -${this.canvasElement.clientHeight / 2})`;
    }

    get LoadingScreenIsShown () {
        return this.$store.state.loadingScreen.LoadingScreenIsShown;
    }

    get isPaytableActive () {
        return this.$store.state.paytable.isActive;
    }
    get isRulesActive () {
        return this.$store.state.rules.isActive;
    }

    get canShowCurrencyCode () {
        return this.$store.state.canShowCurrencyCode;
    }

    get IOSgifAnimationIsActive () {
        return this.$store.state.IOSgifAnimationIsActive;
    }
}
