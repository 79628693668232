
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: 1 })
    progressPercent!: number;

    sourceProgressBarFrame: string = `./assets/${window.lang}/loading/loading_Bar_frame.png`;
    sourceProgressBarInside: string = `./assets/${window.lang}/loading/loading_Bar_empty.png`;
    sourceProgressBarFull: string = `./assets/${window.lang}/loading/loading_bar_full.png`;
    sourceProgressBarSwipe: string = `./assets/${window.lang}/loading/loading_swipe.png`;
}
