
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BetSizeOption extends Vue {
    @Prop({ default: 0 })
    ba!: number;

    @Prop({ default: 0 })
    spins!: number;

    @Prop({ default: false })
    active!: boolean;

    @Prop({ default: 0 })
    index!: boolean;

    handleBetSizeOptionClick () {
        this.$emit('onBetSizeOptionSelected', this.index);
    }
}
