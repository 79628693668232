
import { Component, Vue, Prop } from "vue-property-decorator";
import translation from "./translations";

@Component({})
export default class GameUIContainer extends Vue {
    @Prop({ default: false })
    isActive!: boolean;

    get text() {
        const language = window.lang.toLowerCase();
        const allTranslation = translation as any;

        return allTranslation[language]
            ? allTranslation[language]['game:portrait.mode.warning']
            : 'Please switch back to portrait mode to continue the game'
    }
}
