import { IPopup } from './contracts/IPopup';
import { IEvents } from './contracts/IEvents';

export const EVENTS: IEvents = {
    LOAD: 'LOAD',
    SPIN_BUTTON_CLICKED: 'SPIN_BUTTON_CLICKED',
    RESIZE_WINDOW: 'RESIZE_WINDOW',
    GAME_CANVAS_READY: 'GAME_CANVAS_READY',
    LOAD_PROGRESS_RESOURCES: 'LOAD_PROGRESS_RESOURCES',
    RESOURCES_READY: 'RESOURCES_READY',
    STAKE_CHANGE: 'STAKE_CHANGE',
    TURBO_BUTTON_CHANGE: 'TURBO_BUTTON_CHANGE',
    AUTO_PLAY_ACTIVATED: 'AUTO_PLAY_ACTIVATED',
    MAIN_SCREEN_READY: 'MAIN_SCREEN_READY',
    PROMOTIONAL_FREE_SPINS: 'PROMOTIONAL_FREE_SPINS',
    INFORMATION_RECEIVE: 'INFORMATION_RECEIVE',
    FORCE_STOP_ANIMATIONS: 'FORCE_STOP_ANIMATIONS',
    FS_PLAY_BUTTON_CLICKED: 'FS_PLAY_BUTTON_CLICKED',
    HIDE_FOOTER: 'HIDE_FOOTER',
    SHOW_FOOTER: 'SHOW_FOOTER',
    SPACE_BAR_HOLDING: 'SPACE_BAR_HOLDING',
    STOP_AUTOPLAY_BUTTON_CLICKED: 'STOP_AUTOPLAY_BUTTON_CLICKED',
    HISTORY_BUTTON_CLICKED: 'HISTORY_BUTTON_CLICKED',
    HOME_BUTTON_CLICKED: 'HOME_BUTTON_CLICKED',
    HISTORY_RECEIVED: 'HISTORY_RECEIVED',
    CONTINUE_PLAYING_CLICKED: 'CONTINUE_PLAYING_CLICKED',
    PLAY_SOUND: 'PLAY_SOUND',
    STOP_SOUNDS: 'STOP_SOUNDS',
    STOP_RAF: 'STOP_RAF',
    RESUME_RAF: 'RESUME_RAF',
    FEIM_SET_SOUNDS: 'FEIM_SET_SOUNDS',
    FEIM_SET_TURBO: 'FEIM_SET_TURBO',
    POPUP_CONFIRMED: 'POPUP_CONFIRMED'
};

export const POPUP_NAMES: IPopup = {
    PROMOTIONAL_FREE_SPINS: 'PROMOTIONAL_FREE_SPINS',
    PROMOTIONAL_FREE_SPINS_ENDED: 'PROMOTIONAL_FREE_SPINS_ENDED',
    GAME_UNFINISHED: 'GAME_UNFINISHED',
    FREESPINS_POPUP: 'FREESPINS_POPUP',
    INFORMATIONAL: 'INFORMATIONAL',
    ERROR: 'ERROR',
    REALITY_CHECK: 'REALITY_CHECK'
};

export const INITIAL_RESOURCES: any = [ // for every game, we should add loading screen resources, and game background !!!
    './assets/{{lang}}/loading/aztec_loading_button_hover.png',
    './assets/{{lang}}/loading/aztec_loading_button_normal.png',
    './assets/{{lang}}/loading/loading_Bar_empty.png',
    './assets/{{lang}}/loading/loading_Bar_frame.png',
    './assets/{{lang}}/loading/loading_bar_full.png',
    './assets/{{lang}}/loading/loading_Bar_inside.png',
    './assets/{{lang}}/loading/silverback_logo.png',
    './assets/{{lang}}/loading/relax_logo.png',
    './assets/{{lang}}/loading/loading_image.jpg',
    './assets/{{lang}}/loading/loading_swipe.png',
    './assets/{{lang}}/common/symbol_swipe.png',
    './assets/{{lang}}/common/desktop_bgr.jpg'
];
