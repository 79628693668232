

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';
import CloseButton from './CloseButton.vue';
import ProgressBar from './ProgressBar.vue';
import GlobalMixin from '../../mixins/GlobalMixin.vue';

@Component({
    components: {
        CloseButton,
        ProgressBar
    }
})
export default class LoadingScreen extends Mixins(GlobalMixin) {
    @Prop({ default: true })
    LoadingScreenIsShown!: boolean;

    @Prop({ default: 100 })
    width!: number;

    @Prop({ default: 100 })
    height!: number;

    percent: number = 0;
    sourceSbLogo: string = `./assets/${window.lang}/loading/silverback_logo.png`;
    sourceRelaxLogo: string = `./assets/${window.lang}/loading/relax_logo.png`;
    loadingTxt: string = `./assets/${window.lang}/ui/texts/ui_loading.png`;
    backgroundLoadingScreen: string = `./assets/en_US/loading/loading_image.jpg`;

    mounted () {
        this.$nextTick(() => {
            EventBus.$on('LOAD_PROGRESS_RESOURCES', this.showProgress);
            EventBus.$on('RESOURCES_READY', this.enableCloseButton);
        });
    }
    showProgress (context: any): void {
        this.percent = context.value;
    }
    enableCloseButton (): void {
        this.$store.dispatch('closeButton/makeCloseButtonActive');
    }
    handleCloseButtonClick (): void {
        EventBus.$emit('MAIN_SCREEN_READY', { type: 'MAIN_SCREEN_READY' });
        this.$store.dispatch('loadingScreen/LoadingScreenIsShown');

        this.playSound('UI_Click_2');
        this.playSound('GameIntroStart');
    }
    get closeButtonIsActive () {
        return this.$store.state.closeButton.closeButtonIsActive;
    }
}

