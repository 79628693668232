
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import anime from 'animejs';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: false })
    closeButtonIsActive!: boolean;
    private playButtonTimeout: any;
    playButtonFrameSource: string = `./assets/${window.lang}/loading/button_frame2.png`;
    playButtonNormalSource: string= `./assets/${window.lang}/loading/button_normal2.png`;
    playButtonHoverSource: string = `./assets/${window.lang}/loading/button_hover2.png`;
    playButtonSwipeSource: string = `./assets/${window.lang}/common/symbol_swipe.png`;
    playButtonText: string = `./assets/${window.lang}/ui/texts/ui_play.png`;
    playButtonTextHover: string = `./assets/${window.lang}/ui/texts/ui_play_hover.png`;

    mounted () {
        this.$nextTick(() => {
            this.animatePlayButtonSwipe();
        });
    }

    animatePlayButtonSwipe () {
        this.playButtonTimeout = setTimeout(() => {
            this.playButtonSwipeAnimation();
        }, 1000);
    }
    hoverOver (): void {
        anime({
            targets: this.$refs.playButtonHover,
            duration: 170,
            easing: 'easeOutQuint',
            opacity: 1
        });
        (this.$refs.playButtonText as HTMLElement).style.backgroundImage = `url(${this.playButtonTextHover})`;
    }
    hoverOut (): void {
        anime({
            targets: this.$refs.playButtonHover,
            duration: 170,
            easing: 'easeOutQuint',
            opacity: 0
        });
        (this.$refs.playButtonText as HTMLElement).style.backgroundImage = `url(${this.playButtonText})`;
    }
    playButtonSwipeAnimation () {
        anime({
            targets: this.$refs.playButtonSwipe,
            left: [-125, 390],
            duration: 500,
            easing: 'easeInOutSine',
            loop: true,
            endDelay: 10000
        });
    }

    handleCloseButtonClick (): void {
        if (!this.closeButtonIsActive) {
            return;
        }
        this.$emit('onCloseButtonClick');
    }

    beforeDestroy () {
        clearTimeout(this.playButtonTimeout);
    }
}
