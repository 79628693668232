import { IHistoryState } from './contracts/history';

const state: IHistoryState = {
    isActive: true
};

const actions = {
    historyIsActive ({ commit }: any) {
        commit('historyIsActive');
    },
    historyIsDisabled ({ commit }: any) {
        commit('historyIsDisabled');
    }
};

const mutations = {
    historyIsActive (state: IHistoryState) {
        state.isActive = true;
    },
    historyIsDisabled (state: IHistoryState) {
        state.isActive = false;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
