
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class SwitchCheckbox extends Vue {
    private isSelected: boolean = false;

    @Watch('isSelected')
    handleOnSwitched (value: boolean): void {
        this.$emit('input', value);
    }
}
