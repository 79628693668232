
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import Icon from './../shared/Icons/Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class SpinButton extends Vue {
    canAnimateArrows: boolean = false;

    private minArrowsAnimationDelay: number = this.IosVersion! < 14 ? 900 : 10000; // in ms
    private minArrowsAnimationDelayAfterStopIcon: number = 1000;
    private arrowTransitionDuration: number = 800; // in ms

    spinButtonFrameSource = `./assets/${window.lang}/spin/ui_spin_frame.png`;
    spinButtonWrapSource = `./assets/${window.lang}/spin/ui_spin_normal.png`;
    spinButtonWrapSourceActive = `./assets/${window.lang}/spin/ui_spin_active.png`;
    spinButtonClickableBeforeSource = `./assets/${window.lang}/spin/ui_spin_push.png`;

    private timeouts: any = {
        timeoutAfterStopIcon: null,
        transitionTimeout: null
    }

    @Prop({ default: true })
    spinButtonIsActive!: boolean;

    @Watch('canShowActiveStateOfSpinButton')
    onShowStopButtonChanged (value: string, oldValue: string) {
        (this.$refs.spinButtonNormal as HTMLElement).style.opacity = '1';
        (this.$refs.spinButtonActive as HTMLElement).style.opacity = '0';

        clearTimeout(this.timeouts.timeoutAfterStopIcon);
        if (!value && this.canAnimateArrows) {
            this.timeouts.timeoutAfterStopIcon = setTimeout(() => this.animateArrows(), this.minArrowsAnimationDelayAfterStopIcon);
        }
    }

    handleMouseEnter (): void {
        if (window.innerWidth > 1025 && !this.canShowActiveStateOfSpinButton) {
            (this.$refs.spinButtonNormal as HTMLElement).style.opacity = '0';
            (this.$refs.spinButtonActive as HTMLElement).style.opacity = '1';
        }
        // (this.$refs.spinButtonWrapper as HTMLElement).classList.add('hovered');
    }

    handleMouseLeave (): void {
        if (window.innerWidth > 1025 && this.spinButtonIsActive) {
            if (this.canShowStopButton) {
                (this.$refs.spinButtonNormal as HTMLElement).style.opacity = '0';
                (this.$refs.spinButtonActive as HTMLElement).style.opacity = '1';
            } else {
                (this.$refs.spinButtonNormal as HTMLElement).style.opacity = '1';
                (this.$refs.spinButtonActive as HTMLElement).style.opacity = '0';
            }
            // this.spinButtonWrapSource = `/assets/${window.lang}/spin/ui_spin_normal.png`;
        }
        // (this.$refs.spinButtonWrapper as HTMLElement).classList.remove('hovered');
    }

    handleSpinClick () {
        if (!this.spinButtonIsActive) {
            return;
        }

        this.$emit('onButtonClick');
    }

    animateArrows () {
        clearTimeout(this.timeouts.transitionTimeout);
        const arrows: any = this.$refs.spinArrows;

        arrows.$el.style.transition = `transform ${this.arrowTransitionDuration}ms cubic-bezier(0.23, 1, 0.32, 1)`;
        arrows.$el.style.transform = 'rotate(360deg)';

        this.clearTransitinAndTransform(arrows);
        this.canAnimateArrows = false;
    }

    private clearTransitinAndTransform (arrows: any) {
        this.timeouts.transitionTimeout = setTimeout(() => {
            arrows.$el.style.transition = '';
            arrows.$el.style.transform = 'rotate(0)';
        }, this.arrowTransitionDuration);
    }

    mounted () {
        this.$nextTick(this.handleNextTick);
    }

    handleNextTick () {
        this.initEvents();
        this.initArrowAnimation();
    }

    initArrowAnimation () {
        if (this.$refs.spinArrows) {
            setInterval(() => this.arrowsAnimation(), this.minArrowsAnimationDelay);
        }
    }

    initEvents () {
        const isTouchDevice = 'ontouchstart' in document.documentElement;

        if (isTouchDevice) {
            (this.$refs.spinButtonClickable as HTMLElement).addEventListener('touchstart', this.handleTouchStart.bind(this));
            (this.$refs.spinButtonClickable as HTMLElement).addEventListener('touchend', this.handleTouchEnd.bind(this));
        } else {
            (this.$refs.spinButtonClickable as HTMLElement).addEventListener('mousedown', this.handleMouseDownEvent.bind(this));
            (this.$refs.spinButtonClickable as HTMLElement).addEventListener('mouseup', this.handleMouseUpEvent.bind(this));
            (this.$refs.spinButtonClickable as HTMLElement).addEventListener('mouseleave', this.handleMouseUpEvent.bind(this));
        }
    }

    arrowsAnimation () {
        if (this.canShowActiveStateOfSpinButton) {
            this.canAnimateArrows = true;
            return;
        }

        this.animateArrows();
    }

    handleTouchStart () {
        this.addDownClassToSpinButtonElement();
    }

    handleTouchEnd () {
        this.removeDownClassToSpinButtonElement();
    }

    handleMouseDownEvent (event: Event) {
        if ((event as MouseEvent).which === 1) {
            this.addDownClassToSpinButtonElement();
        }
    }

    handleMouseUpEvent (event: Event) {
        if ((event as MouseEvent).which === 1) {
            this.removeDownClassToSpinButtonElement();
        }
    }

    addDownClassToSpinButtonElement () {
        if (this.spinButtonIsActive) {
            this.$el.classList.add('down');
            if (!this.canShowStopButton) {
                (this.$refs.spinButtonClickableBefore as HTMLElement).style.opacity = '1';
            }
        }
    }

    removeDownClassToSpinButtonElement () {
        if (this.spinButtonIsActive) {
            this.$el.classList.remove('down');
            (this.$refs.spinButtonClickableBefore as HTMLElement).style.opacity = '0';
        }
    }

    get canShowStopButton () {
        // return this.$store.state.spinButton.canShowStopButton; If we want to show the stop button/state ( now we don't , it's like red tiger btn)
        return false;
    }
    private get IosVersion (): number | undefined {
        const agent = window.navigator.userAgent;
        const start = agent.indexOf('OS ');
        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            return Number(agent.substr(start + 3, 3).replace('_', '.'));
        }
        return undefined;
    }
    get canShowActiveStateOfSpinButton () {
        return this.$store.state.spinButton.canShowStopButton;
    }

    get canSetOpacity () {
        return this.$store.state.spinButton.canSetOpacity;
    }
}
