import { LoadingScreenState } from '../contracts/LoadingScreen/loadingScreen';

const state: LoadingScreenState = {
    LoadingScreenIsShown: true
};

const actions = {
    LoadingScreenIsShown ({ commit }: any) {
        commit('LoadingScreenIsShown');
    }

};

const mutations = {
    LoadingScreenIsShown (state: any) {
        state.LoadingScreenIsShown = false;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
