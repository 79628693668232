import { PromotionalFreeSpinsState, PromotionalFreeSpins, BetOption } from './contracts/promotionalFreeSpins';

const state: PromotionalFreeSpinsState = {
    promotionalFreeSpins: null,
    selectedBetSize: null,
    totalWin: 0
};

const actions = {
    setFreeSpins ({ commit }: any, promotionalFreeSpins: PromotionalFreeSpins): void {
        commit('setFreeSpins', promotionalFreeSpins);
    },
    setBetSize ({ commit }: any, selectedBetSize: BetOption) {
        commit('setBetSize', selectedBetSize);
    },
    setTotalWin ({ commit }: any, totalWin: number) {
        commit('setTotalWin', totalWin);
    }
};

const mutations = {
    setFreeSpins (state: PromotionalFreeSpinsState, promotionalFreeSpins: PromotionalFreeSpins | null) {
        state.promotionalFreeSpins = promotionalFreeSpins;
    },
    setBetSize (state: PromotionalFreeSpinsState, selectedBetSize: BetOption) {
        state.selectedBetSize = selectedBetSize;
    },
    setTotalWin (state: PromotionalFreeSpinsState, totalWin: number) {
        state.totalWin = totalWin;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
