import { IRulesState, IRules } from './contracts/rules';

const state: IRulesState = {
    isActive: false,
    rules: null
};

const actions = {
    showRules ({ commit }: any) {
        commit('showRules');
    },
    hideRules ({ commit }: any) {
        commit('hideRules');
    },
    setRules ({ commit }: any, rules: IRules) {
        commit('setRules', rules);
    }
};

const mutations = {
    showRules (state: IRulesState) {
        state.isActive = true;
    },
    hideRules (state: IRulesState) {
        state.isActive = false;
    },
    setRules (state: IRulesState, rules: IRules[]) {
        state.rules = rules;
    }
};

const getters = {
    getSymbolPays (state: IRulesState) {
        return (key: string) => {
            if (state.rules) {
                return state.rules.filter((symbol: IRules) => symbol.symbol === key);
            }

            return [];
        };
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
