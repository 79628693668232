import { CloseButtonState } from './../contracts/LoadingScreen/closeButton';

const state: CloseButtonState = {
    closeButtonIsActive: false
};

const actions = {
    makeCloseButtonActive ({ commit }: any): void {
        commit('setCloseButtonActive');
    }
};

const mutations = {
    setCloseButtonActive (state: CloseButtonState) {
        state.closeButtonIsActive = true;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
