import { ILocale } from './contracts/locale';
import { CURRENCIES } from './../../config/currencies';

const state: ILocale = {
    currency: 'EUR',
    currencyOptions: null
};

const actions = {
    setCurrency ({ commit }: any, currency: string) {
        commit('setCurrency', currency);
    }
};

const mutations = {
    setCurrency (state: ILocale, currency: string) {
        state.currency = currency;
        state.currencyOptions = CURRENCIES[currency];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
