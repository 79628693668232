import { IPopupState } from './contracts/IPopup';

const state: IPopupState = {
    isPromotionalFreeSpinsPopupActive: false,
    isGameUnfinishedPopupActive: false,
    isInformationalPopupActive: false,
    isErrorPopupActive: false,
    isRealityCheckPopupActive: false,
    isAutoplayModalActive: false,
    isHistoryPopupActive: false,
    isFreespinsPopupActive: false,
    informationText: '',
    errorText: '',
    realityCheckData: {}
};

const actions = {
    hidePopups ({ commit }: any) {
        commit('hidePopups');
    },
    showPromotionalFreeSpinsPopup ({ commit }: any): void {
        commit('hidePopups');
        commit('showPromotionalFreeSpinsPopup');
    },
    hidePromotionalFreeSpinsPopup ({ commit }: any): void {
        commit('hidePromotionalFreeSpinsPopup');
    },
    showFreespinsPopup ({ commit }: any): void {
        commit('hidePopups');
        commit('showFreespinsPopup');
    },
    hideFreespinsPopup ({ commit }: any): void {
        commit('hideFreespinsPopup');
    },

    showGameUnfinishedPopup ({ commit }: any): void {
        commit('hidePopups');
        commit('showGameUnfinishedPopup');
    },
    hideGameUnfinishedPopup ({ commit }: any): void {
        commit('hideGameUnfinishedPopup');
    },

    showAutoplayModal ({ commit }: any): void {
        commit('hidePopups');
        commit('showAutoplayModal');
    },
    hideAutoplayModal ({ commit }: any): void {
        commit('hideAutoplayModal');
    },
    showHistoryPopup ({ commit }: any) {
        commit('hidePopups');
        commit('showHistoryPopup');
    },
    hideHistoryPopup ({ commit }: any) {
        commit('hideHistoryPopup');
    },
    showInformationalPopup ({ commit }: any, informationText: string): void {
        commit('hidePopups');
        commit('showInformationalPopup', informationText);
    },
    hideInformationalPopup ({ commit }: any): void {
        commit('hideInformationalPopup');
    },
    showErrorPopup ({ commit }: any, errorText: string): void {
        commit('hidePopups');
        commit('showErrorPopup', errorText);
    },
    hideErrorPopup ({ commit }: any): void {
        commit('hideErrorPopup');
    },
    showRealityCheckPopup ({ commit }: any, realityCheckData: any): void {
        commit('hidePopups');
        commit('showRealityCheckPopup', realityCheckData);
    },
    hideRealityCheckPopup ({ commit }: any): void {
        commit('hideRealityCheckPopup');
    }
};

const mutations = {
    hidePopups (state: IPopupState) {
        state.isAutoplayModalActive = false;
        state.isHistoryPopupActive = false;
    },
    showPromotionalFreeSpinsPopup (state: IPopupState) {
        state.isPromotionalFreeSpinsPopupActive = true;
    },
    hidePromotionalFreeSpinsPopup (state: IPopupState) {
        state.isPromotionalFreeSpinsPopupActive = false;
    },

    showFreespinsPopup ({ commit }: any): void {
        state.isFreespinsPopupActive = true;
    },
    hideFreespinsPopup ({ commit }: any): void {
        state.isFreespinsPopupActive = false;
    },

    showGameUnfinishedPopup (state: IPopupState) {
        state.isGameUnfinishedPopupActive = true;
    },
    hideGameUnfinishedPopup (state: IPopupState) {
        state.isGameUnfinishedPopupActive = false;
    },

    showAutoplayModal (state: IPopupState) {
        state.isAutoplayModalActive = true;
    },

    hideAutoplayModal (state: IPopupState) {
        state.isAutoplayModalActive = false;
    },

    showHistoryPopup (state: IPopupState) {
        state.isHistoryPopupActive = true;
    },
    hideHistoryPopup (state: IPopupState) {
        state.isHistoryPopupActive = false;
    },

    showInformationalPopup (state: IPopupState, informationText: string) {
        state.isInformationalPopupActive = true;
        state.informationText = informationText;
    },
    hideInformationalPopup (state: IPopupState) {
        state.isInformationalPopupActive = false;
    },
    showErrorPopup (state: IPopupState, errorText: string) {
        state.isErrorPopupActive = true;
        state.errorText = errorText;
    },
    hideErrorPopup (state: IPopupState) {
        state.isErrorPopupActive = false;
    },
    showRealityCheckPopup (state: IPopupState, realityCheckData: any) {
        state.isRealityCheckPopupActive = true;
        state.realityCheckData = realityCheckData;
    },
    hideRealityCheckPopup (state: IPopupState) {
        state.isRealityCheckPopupActive = false;
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
