import { TurboSpinButtonState } from './contracts/turboSpinButton';

const state: TurboSpinButtonState = {
    isActive: false,
    isVisible: true
};

const actions = {
    toggleActive ({ commit }: any): void {
        commit('toggleActive');
    },
    disableTurboSpinButton ({ commit }: any): void {
        commit('disableTurboSpinButton');
    },
    enableTurboSpinButton ({ commit }: any): void {
        commit('enableTurboSpinButton');
    },
    showTurboSpinButton ({ commit }: any, isTurboVisible: boolean): void {
        commit('showTurboSpinButton', isTurboVisible);
    }
};

const mutations = {
    toggleActive (state: TurboSpinButtonState) {
        state.isActive = !state.isActive;
    },
    disableTurboSpinButton (state: TurboSpinButtonState) {
        state.isActive = false;
    },
    enableTurboSpinButton (state: TurboSpinButtonState) {
        state.isActive = true;
    },
    showTurboSpinButton (state: TurboSpinButtonState, isTurboVisible: boolean): void {
        state.isVisible = isTurboVisible;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
