
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '../utils/EventBus';

@Component({})
export default class GlobalMixin extends Vue {
    playSound (name: string = '', options: any = {}) {
        EventBus.$emit('PLAY_SOUND', {
            type: 'PLAY_SOUND',
            value: {
                name,
                options
            }
        });
    }
}
