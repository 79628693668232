import { GAME_CONFIG } from './../../silverback/src/configs/common.config';
import { INITIAL_RESOURCES } from './config/constants';
import App from './App';

declare global {
    interface Window {
        __SILVERBACK__: any;
        lang: any;
        currency: any;
        FULLSCREEN: any;
        hidehome: any;
        FEIM: any;
        deviceInfo: any;
        rtp: any;
    }
}

class Main {
    protected app: App;

    constructor (config: any) {
        this.app = new App(config);
    }

    get events () {
        return this.app.getEvents();
    }
}

window.__SILVERBACK__ = window.__SILVERBACK__ || {};
window.__SILVERBACK__.VueRenderer = Main;

(function () {
    let counter = 0;
    let totalImages = INITIAL_RESOURCES.length;
    window.lang = new URLSearchParams(window.location.search).get('lang') || 'en_US';
    window.FULLSCREEN = new URLSearchParams(window.location.search).get('fullscreen') || GAME_CONFIG.FULL_SCREEN;
    window.hidehome = new URLSearchParams(window.location.search).get('hidehome') || GAME_CONFIG.HIDE_HOME;
    const gameName = new URLSearchParams(window.location.search).get('gameid') || GAME_CONFIG.GAME_NAME;
    if (gameName) {
        const rtp = gameName.match(/(\d+)/);
        rtp ? (window.rtp = rtp[0]) : window.rtp = GAME_CONFIG.RTP;
    }

    const supportedLanguages = [
        'en_GB',
        'sv_SE',
        'fi_FI',
        'nb_NO',
        'et_EE',
        'ro_RO',
        'da_DK',
        'de_DE',
        'nl_NL',
        'zh_CN',
        'zh_TW',
        'ru_RU',
        'th_TH',
        'tr_TR',
        'ja_JP',
        'pl_PL',
        'pt_BR',
        'es_MX',
        'ko_KR',
        'vi_VN',
        'cs_CZ',
        'id_ID',
        'it_IT',
        'km_KH',
        'fr_FR',
        'ka_GE',
        'el_GR',
        'lt_LT',
        'pt_PT',
        'ms_MY',
        'es_ES',
        'hr_HR',
        'sk_SK',
        'hu_HU',
        'fr_BE',
        'nl_BE'
    ];
    !supportedLanguages.includes(window.lang) && (window.lang = 'en_US');

    let onloadCallback = function () {
        counter++;
        if (totalImages !== 1 && counter < totalImages) {
            return;
        }
        allLoadedCallback();
    };
    for (let image = 0; image < totalImages; image++) {
        let currImage = new Image();
        let src = INITIAL_RESOURCES[image].replace('{{lang}}', window.lang);
        currImage.onload = onloadCallback;
        currImage.src = src;
    }
    let allLoadedCallback = function () {
        const b = new window.__SILVERBACK__.Silverback();
    };
})();
