import { IPaytableState, IPaytable } from './contracts/paytable';

const state: IPaytableState = {
    isActive: false,
    paytable: null
};

const actions = {
    showPaytable ({ commit }: any) {
        commit('showPaytable');
    },
    hidePaytable ({ commit }: any) {
        commit('hidePaytable');
    },
    setPaytable ({ commit }: any, paytable: IPaytable) {
        commit('setPaytable', paytable);
    }
};

const mutations = {
    showPaytable (state: IPaytableState) {
        state.isActive = true;
    },
    hidePaytable (state: IPaytableState) {
        state.isActive = false;
    },
    setPaytable (state: IPaytableState, paytable: IPaytable[]) {
        state.paytable = paytable;
    }
};

const getters = {
    getSymbolPays (state: IPaytableState) {
        return (key: string) => {
            if (state.paytable) {
                return state.paytable.filter((symbol: IPaytable) => symbol.symbol === key).slice().reverse();
            }

            return [];
        };
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
