
import { Component, Vue } from 'vue-property-decorator';
import { PromotionalFreeSpinsActions } from './contracts/PromotionalFreeSpinsActions';
import { EventBus } from './../../utils/EventBus';
import { EVENTS } from '../../config/constants';

import BetSizeOptions from './BetSizeOptions.vue';

@Component({
    components: {
        BetSizeOptions
    }
})
export default class PromotionalFreeSpins extends Vue {
    fsCongrats: string = `./assets/${window.lang}/freerounds/texts/freerounds_introText.png`;
    fsOptions: string = `./assets/${window.lang}/freerounds/texts/freerounds_introText2.png`;
    fsUnfinished: string = `./assets/${window.lang}/freerounds/texts/freerounds_unfinished.png`;
    fsContinueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    fsSkipBtn: string = `./assets/${window.lang}/ui/texts/ui_play_later.png`;
    fsDeleteBtn: string = `./assets/${window.lang}/ui/texts/ui_no_thanks.png`;
    hasExpired: boolean = this.$store.state.promotionalFreeSpins.promotionalFreeSpins.expires * 1000 < Date.now();

    handlePromotionalFreeSpinsAccept () {
        const spins = this.$store.state.promotionalFreeSpins.selectedBetSize ? this.$store.state.promotionalFreeSpins.selectedBetSize.spins : this.$store.state.promotionalFreeSpins.promotionalFreeSpins.spins;
        const betAmount = this.$store.state.promotionalFreeSpins.selectedBetSize ? this.$store.state.promotionalFreeSpins.selectedBetSize.ba : this.$store.state.promotionalFreeSpins.promotionalFreeSpins.ba;
        EventBus.$emit('STAKE_CHANGE', { type: 'STAKE_CHANGE', value: betAmount });
        this.$store.dispatch('stake/setValue', betAmount);
        EventBus.$emit(EVENTS.PROMOTIONAL_FREE_SPINS,
            {
                type: EVENTS.PROMOTIONAL_FREE_SPINS,
                action: PromotionalFreeSpinsActions.CONTINUE,
                autoplay: this.$store.state.promotionalFreeSpins.promotionalFreeSpins.autoplay,
                spins: spins,
                betAmount: betAmount
            }
        );
    }

    handlePromotionalFreeSpinsSkip () {
        EventBus.$emit(EVENTS.PROMOTIONAL_FREE_SPINS,
            {
                type: EVENTS.PROMOTIONAL_FREE_SPINS,
                action: PromotionalFreeSpinsActions.SKIP
            }
        );
    }
    handlePromotionalFreeSpinsDelete () {
        EventBus.$emit(EVENTS.PROMOTIONAL_FREE_SPINS,
            {
                type: EVENTS.PROMOTIONAL_FREE_SPINS,
                action: PromotionalFreeSpinsActions.DELETE
            }
        );
    }

    get promotionalFreeSpins () {
        return this.$store.state.promotionalFreeSpins.promotionalFreeSpins;
    }
}
