
import { Component, Vue, Watch } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';

@Component({
    components: {}
})
export default class History extends Vue {
    mounted () {
        this.$nextTick(() => {
            EventBus.$on('HISTORY_RECEIVED', (data: any) => {
                (this.$refs.history as HTMLElement) && ((this.$refs.history as HTMLElement).innerHTML = data.value);
            });
        });
    }
}
