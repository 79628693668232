

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: '' })
    icon!: string;

    @Prop({ default: '' })
    ext!: string;

    iconSource = `./assets/${window.lang}/icons/${this.icon}.${this.ext}`;
}

