
import { Component, Prop, Mixins } from 'vue-property-decorator';
import Icon from './../Icons/Icon.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({
    components: {
        Icon
    }
})
export default class LoadingScreen extends Mixins(GlobalMixin) {
    @Prop({ default: false })
    isActive!: boolean;

    @Prop({ default: false })
    canClose!: boolean;

    handleCloseButtonClick () {
        this.playSound('UI_Click_2');
        this.$emit('onCloseClicked');
    }
}

